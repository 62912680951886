import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './scss/core.scss';
import 'bulma/css/bulma.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

import './tools/I18n';

import { INVITE_ID_KEY } from './constants';
import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigate';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';

// TODO ?REMOVE CHECK AUTH0 REDIRECTS
// export const history = createBrowserHistory();

// export const onRedirectCallback = (appState: any) => {
//   history.replace(appState?.returnTo || window.location.pathname);
// };

const queryString = window.location.search;
const params = new URLSearchParams(queryString);
const inviteCode = params.get('invite');
if (inviteCode) {
  localStorage.setItem(INVITE_ID_KEY, inviteCode);
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner/>}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
