import React, { useEffect } from 'react';
import env from '../../Env';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Patient from '../../pages/Patient/Patient';
import ExercisesList from '../../pages/Exercises/ExercisesList';
import Exercise from '../../pages/Exercises/Exercise';
import Help from '../../pages/HelpPages/Help';
import TermsOfUse from '../../pages/HelpPages/TermsOfUse';
import PrivacyPolicy from '../../pages/HelpPages/PrivacyPolicy';
import PageNotFound from '../PageNotFound/PageNotFound';
import { RestfulProvider } from 'restful-react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import VerifyEmailMessage from '../VerifyEmailMessage/VerifyEmailMessage';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import AppPrivacyPolicy from '../../pages/HelpPages/AppPrivacyPolicy';
import ExerciseForm from '../ExerciseForm/ExerciseForm';
import { LanguagePicker } from '../../pages/LanguagePicker/LanguagePicker';
import { LanguageProvider } from '../../hooks/useLanguagePreference';
import PhysioMyDetails from '../../pages/PhysioMyDetails/PhysioMyDetails';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useCookies } from 'react-cookie';
import { MainLayout } from '../../layout/MainLayout';
import { PageLayout } from '../../layout/PageLayout';
import { isGlobalSite } from '../../helpers/countrySubdomainHelper';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const gaCode = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getAccessTokenSilently, logout, error, isAuthenticated, isLoading } = useAuth0();
  const { setGaCode } = useGoogleAnalytics();
  const cookies = useCookies()[0];

  useEffect(() => {
    if (cookies.acceptCookie === 'true' && gaCode) {
      setGaCode(gaCode);
    }
  }, [cookies.acceptCookie, setGaCode]);

  const onError = (statuscode: number | undefined) => {
    switch (statuscode) {
      case 402: {
        navigate('/home');
        break;
      }
      default: {
        return (
          <div className="solid-background">
            <ErrorMessage message="An unexpected error has occurred. If the problem persists please contact us." />
          </div>
        );
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner/>
  }

  // Prevent login to global site
  if (isGlobalSite) {
    if (isAuthenticated) {
      logout({
        returnTo: window.location.origin
      });
      return <LoadingSpinner/>
    }
  }

  if (error) {
    if (error.message === 'Please verify your email before logging in.') {
      return (
        <div className="solid-background">
          <VerifyEmailMessage />;
        </div>
      );
    }
    logout();
  }
  return (
    <RestfulProvider
      base={`${env.baseUrl}/api/`}
      requestOptions={async () => {
        let token = '';
        try {
          token = await getAccessTokenSilently();
        } catch (err) {
          // swallow error
        }
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        };
      }}
      onError={({ status }) => onError(status)}
    >
      <LanguageProvider>
        <MainLayout cookies={cookies} gaCode={gaCode}>
          <Routes>
            <Route path="/" element={<LanguagePicker />} />
            <Route path="/home" element={<ProtectedRoute component={Home} />} />
            <Route path="/my-details" element={<ProtectedRoute component={PhysioMyDetails} />} />
            <Route path="/patient/add" element={<ProtectedRoute component={Patient} />} />
            <Route path="/patient/:patientId" element={<ProtectedRoute component={Patient} />} />
            <Route path="/exerciseslist" element={<ProtectedRoute component={ExercisesList} />} />
            <Route
              path="/patient/:patientId/programme/:programmeId"
              element={<ProtectedRoute component={ExercisesList} />}
            />
            <Route path="/exercises/:exerciseId" element={<Exercise isProgramme={false} />} />
            <Route
              path="/exercise/:exerciseId"
              element={
                <>
                  <PageLayout showHeader={false}>
                    {' '}
                    <ExerciseForm />{' '}
                  </PageLayout>{' '}
                </>
              }
            />
            <Route
              path="/programme/:programmeId/exercise/add/:exerciseId"
              element={
                <ProtectedRoute
                  component={Exercise}
                  props={{ isProgramme: true, newExercise: true }}
                />
              }
            />
            <Route
              path="/programme/:programmeId/exercise/:exerciseId"
              element={<Exercise isProgramme={true} />}
            />
            <Route path="/help" element={<ProtectedRoute component={Help} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/app-privacy-policy" element={<AppPrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </MainLayout>
      </LanguageProvider>
    </RestfulProvider>
  );
};

export default App;
