interface IEnv {
  authDomain: string;
  authClientId: string;
  useBasicAuth: boolean;
  globalSiteUrl: string;
  countryUrlTemplate: string
  baseUrl: string;
  authAudience: string;
}

const env: IEnv = {
  authDomain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  authClientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  useBasicAuth: (process.env.REACT_APP_BASIC_AUTH_TOGGLE as string) === 'true',
  globalSiteUrl: process.env.REACT_APP_GLOBAL_SITE_URL as string,
  countryUrlTemplate: process.env.REACT_APP_COUNTRY_URL_TEMPLATE as string,
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  authAudience: process.env.REACT_APP_AUTH0_API_AUDIENCE as string,
};

export default env;
