import React, { useCallback, useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { useGet, useMutate } from 'restful-react';
import i18n from '../tools/I18n';
import { OkResponse } from '../types/responses';
import { useNavigate } from 'react-router-dom';
import { useGoogleAnalytics } from './useGoogleAnalytics';
import { filterLanguagesForCurrentCountry, getCountryOriginUrl, isGlobalSite } from '../helpers/countrySubdomainHelper';

export interface LanguageSelectOption {
  value: string;
  label: string;
  inferredCountry: string;
  requiresPhysioConfirmation: boolean;
}

interface LanguagePreferenceContext {
  userLanguagePreference: string | null;
  languageOptions: LanguageSelectOption[] | null;
  setLanguagePref: (lang: string) => void;
}

interface LanguageProviderProps {
  children: React.ReactNode;
}

const LanguageContext = createContext<LanguagePreferenceContext | undefined>(undefined);

const LANGUAGE_PREF_KEY = 'languagePreference';
export const REQUESTED_URI_KEY = 'requestedUri';
export const LANG_TO_UPDATE_KEY = 'updateLang';

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [languagePref, setLanguagePref] = useState(localStorage.getItem(LANGUAGE_PREF_KEY));
  const navigate = useNavigate();
  
  const { data: locales } = useGet({
    path: '/locale',
    resolve: (res: OkResponse<LanguageSelectOption>) => {
      return isGlobalSite ? res.responses : filterLanguagesForCurrentCountry(res.responses);
    },
  });

  const setLanguage = useCallback((locale: string) => {
    localStorage.setItem(LANGUAGE_PREF_KEY, locale);
    i18n.changeLanguage(locale);
    setLanguagePref(locale);
  }, []);

  const trySetLanguageFromQuery = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const localeParam = urlParams.get('locale');
    if (localeParam && locales?.some(l => l.value === localeParam)) {
      setLanguage(localeParam);
      return true;
    }
    return false;
  }, [locales, setLanguage]);

  useEffect(() => {
    if (!locales) {
      return;
    }
    
    if (trySetLanguageFromQuery()) {
      return;
    }

    if (languagePref == null) {
      if (isGlobalSite) {
        setLanguage("en-GB");
        return;
      }
      // Automatically set the locale if there's only one
      if (locales && locales.length === 1) {
        setLanguage(locales[0].value);
        return;
      }
      if (window.location.pathname !== '/') {
        // store original route to support deeplinking and language setting
        localStorage.setItem(REQUESTED_URI_KEY, window.location.pathname);
      }
      navigate('/');
    }
    // force user to language selection page if nothing is set in localstorage or if
    // language set is old style 2 digit code not locale code (they then need to re-do the selection)
    if (languagePref?.length === 2) {
      localStorage.removeItem(LANGUAGE_PREF_KEY);
      setLanguagePref(null);
    }
  }, [languagePref, navigate, locales, trySetLanguageFromQuery, setLanguage]);

  const value = { userLanguagePreference: languagePref, languageOptions: locales, setLanguagePref };

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguagePreference = () => {
  const context = useContext(LanguageContext);
  const { raiseEvent } = useGoogleAnalytics();

  if (context === undefined) {
    throw new Error('useLanguagePreference must be used inside a LanguageProvider');
  }

  const { mutate: updateLanguageDetails } = useMutate<string>({
    verb: 'PUT',
    path: '/user/locale',
  });

  // we keep the locale in local strogae as well as db so we can decide if we
  // need to display language selector on load (ie before they log in)
  // note locale is also stored as 'lang' on their Auth0 account
  const changeLanguageHandler = async (lang: LanguageSelectOption, isLoggedIn: boolean) => {
    if (isGlobalSite) {
      window.location.href = getCountryOriginUrl(lang, true);
    } else {
      if (!context?.languageOptions?.find((i) => i.value === lang.value)) {
        throw new Error('Invalid language selection');
      }
      if (isLoggedIn) {
        await updateLanguageDetails({ newLocaleCode: lang });
      } else {
        localStorage.setItem(LANG_TO_UPDATE_KEY, 'true');
      }
      context.setLanguagePref(lang.value);
      localStorage.setItem(LANGUAGE_PREF_KEY, lang.value);
      i18n.changeLanguage(lang.value);
      raiseEvent(lang.value, 'Language changed');
    }
  };

  const setDatabaseLanguage = async () => {
    await updateLanguageDetails({ newLocaleCode: context.userLanguagePreference });
  };

  return { context, changeLanguageHandler, setDatabaseLanguage };
};
