import env from "../Env";
import { LanguageSelectOption } from "../hooks/useLanguagePreference";

// Used for resolving the correct country site agnostic of environment
export const getCountryOriginUrl = (option: LanguageSelectOption, withLocaleQuery: boolean = false) => {
  if (!env.countryUrlTemplate || !env.countryUrlTemplate.includes("{COUNTRYCODE}")) {
    throw new TypeError("countryUrlTemplate is required and should include a {COUNTRYCODE} segment");
  }
  var result = env.countryUrlTemplate.replace("{COUNTRYCODE}", option.inferredCountry.toLowerCase());

  if (withLocaleQuery) {
    result += `?locale=${option.value}`;
  }
  return result;
}

export const filterLanguagesForCurrentCountry = (languages: LanguageSelectOption[]) => {
  const hostnameParts = window.location.hostname.toLowerCase().split(".");
  return languages.filter(l => hostnameParts.includes(l.inferredCountry.toLowerCase()));
}

export const isGlobalSite = window.location.origin.includes(env.globalSiteUrl);