import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button } from '../../components/FormComponents/Buttons/Button';
import './LanguagePicker.scss';
import {
  LanguageSelectOption,
  REQUESTED_URI_KEY,
  useLanguagePreference,
} from '../../hooks/useLanguagePreference';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { isGlobalSite } from '../../helpers/countrySubdomainHelper';

export const LanguagePicker = () => {
  const [chosenLanguage, setChosenLanguage] = useState<LanguageSelectOption | null>(null);
  const {
    context: { languageOptions, userLanguagePreference },
    changeLanguageHandler
  } = useLanguagePreference();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToDeepLinkIfSet = useCallback(() => {
    const deepLinkUri = localStorage.getItem(REQUESTED_URI_KEY);
    if (deepLinkUri != null) {
      localStorage.removeItem(REQUESTED_URI_KEY);
      navigate(deepLinkUri);
    } else {
      navigate('/home');
    }
  }, [navigate]);

  useEffect(() => {
    if (userLanguagePreference && !isGlobalSite) {
      navigateToDeepLinkIfSet();
    }
  }, [userLanguagePreference, navigateToDeepLinkIfSet]);

  const handleSelectChange = (option: LanguageSelectOption | null) => {
    if (!option) {
      return;
    }
    setChosenLanguage(option);
  };

  const handleSubmit = async (evt: any) => {
    evt.preventDefault();
    chosenLanguage && await changeLanguageHandler(chosenLanguage, false);
  };

  return (
    <div className="login-container">
      <div className="col-md-6 align-self-start info">{t('language.intro')}</div>
      <div id="login-box" className="col-md-4 login-box">
        <div className="login-header">
          <div style={{ textAlign: 'center' }}>
            <img
              src="https://genetherapy-web-dev.azurewebsites.net/content/img/logo-login.svg"
              alt="Novartis Flexterity Logo"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="language-form">
          {languageOptions ? (
            <>
              <Select
                options={languageOptions}
                autoFocus
                placeholder={t('language.placeholder')}
                onChange={handleSelectChange}
              />
              <Button
                type="submit"
                variant="outlined"
                colour="black"
                translationKey="language.select"
              />
            </>
          ) : (
            <LoadingSpinner isLocal={true} />
          )}
        </form>
      </div>
    </div>
  );
};
